// COLOURS:

$color-primary: #041e42; // NSW Education dark blue (new guidelines)
$color-secondary: #3a78c3; // NSW Education light blue (new guidelines + adjusted to reach AA contrast ratio)
$color-third: #C8DCF0; // NSW Education lightest blue
$color-red: #ce0037; // NSW Education dark red (new guidelines)
$color-bg-app: #f7f7f7;
$color-focus: #a8d6ff;
$color-light-shade: #f3f4f7;
$color-text-body: #000;
$color-text-light: #666666;
$color-row-hover: #f4f9ff;
$color-selected-item: #c8dcf0;
$color-black: #000;
$color-white: #fff;
$color-orange: #c24700;
$color-orange-2: #dc5800;
$color-red-lighter: #f5e8ea;
$color-green: #13ce66;
$color-green-1: #8cc883;
$color-green-2: #e8f3e4;
$color-green-3: #228000;
$color-field-background: #fafafa;
$color-grey-2: #fafafa;
$color-grey-5: #f2f2f2;
$color-grey-6: #f5f5f5;
$color-grey-10: #e5e5e5;
$color-grey-15: #d9d9d9;
$color-grey-30: #b3b3b3;
$color-grey-60: #666;
$color-grey-dark-70: #4C4F55;
$color-grey-hover: #eaedf0;
$color-warning: #fffaed;
$color-warning-border: #ffd66d;
$color-placeholders: #727272;
$color-training: #B81237;
$color-training-secondary: #1D428A;
$color-primary-new: #002664;

// The color are from doe-ui-core, we can replace them when we use it.
$ads-dark-60: #6D7079;
$ads-dark-80: #333333;
$ads-blue-2: #407EC9;
$ads-grey-01: #22272B;
// BREAKPOINTS:

$breakpoint-desktop: 992px; // This is the point at which we swap to a sidebar layout
$breakpoint-tablet: 768px;
$breakpoint-mobile: 480px;
$breakpoint-iphone: 667px;
$breakpoint-iphone-5: 320px;

// SIZES:

$content-padding: 1rem; // Default padding between content and container
$text-h1: 3.8rem;
$text-h2: 3.2rem;
$text-h3: 2.8rem;
$text-h4: 2.4rem;
$text-h5: 2rem;
$text-h6: 1.8rem;
$text: 1.6rem;
$text-small: 1.4rem;
$text-li: 1.6rem;
$text-normal: 400;
$text-medium: 500;
$text-bold: 700;

$desktop-sidebar-width: 350px; // Width of the application sidebar (containing actions) in desktop mode
$save-bar-height: 3rem; // Height of save/cancel bar at bottom of screen when editing an application
$header-height: 66px; // AppHeader height including any border(s)
$header-height-training-large: 109.5px; // AppHeader height including any border(s) when in mobile view in training mode and welcome view.
$header-height-training-small: 60.5px; // AppHeader height including any border(s) when in mobile view in training mode.
$footer-height: 180px; // AppFooter height including any border(s)

// LAYERING

$z-layers: (
  max: 9999,
  modal: 3000,
  snackbar: 2500,
  menu: 2000,
  overlay: 1000,
  env-info: 500,
  header: 200,
  dropdown: 100,
  base: 1
);

@function z($key) {
  @return map-get($z-layers, $key);
}

// STYLES:

$font-family: "Montserrat", Helvetica, Arial, sans-serif;
$field-border: 1px solid #d9d9d9;
$field-border-radius: 0.2rem;
$card-border-radius: 0.4rem;

// BUTTONS VARIANTS:

$basic-transition: all 0.3s ease-in-out;

@mixin button-bg($bg) {
  background: $bg;

  &:hover {
    background: lighten($bg, 8%);
    transition: $basic-transition;
    text-decoration: none;
  }

  &:active {
    background: lighten($bg, 25%);
  }
}

.btn {
  color: $color-white;
  padding: 1rem 3rem;
  border-radius: 0.5rem;
  border: 1px solid $color-primary;
  font-size: 2em;
  font-weight: $text-medium;
}

.btn-primary {
  @include button-bg($color-primary);
  &.disabled:hover{
    background-color: $color-grey-60;
  }
  &:focus,
  &:active {
    border: 3px solid $ads-blue-2 !important;
  }
}

.btn-third {
  @include button-bg($color-third);
}

.btn-link {
  padding: inherit;
  background: transparent;
  border: none;
}

//
